const getDevConfig = async <T>(): Promise<T> => {
  const jsonFileUrl = `/env.local.json`;

  const response = await fetch(jsonFileUrl);
  const config = await response.json().then((json) => json as T);

  if (!config) {
    throw new Error(`No configuration found in env.local.json`);
  }

  return config;
};

export const getConfig = async <T>(isDev: boolean, getConfigFromWindow: (w: Window) => T): Promise<T> => {
  const config = isDev ? ((await getDevConfig()) as T) : getConfigFromWindow(window);
  return Object.freeze(config);
};
