import { createContext } from 'react';

import { User } from 'oidc-client-ts';

export interface AuthenticationContextProps {
  user: User | null | undefined;
  getAccessToken: () => Promise<string>;
  logout: () => Promise<void>;
  login: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthenticationContext = createContext<AuthenticationContextProps>(null!); // This will never be null
